var app = angular.module('app', [])
    .controller('appController', ['$scope', '$http', function appController($scope, $http) {
    $scope.data = {
        ville: null,
        type: null,
        budgetMin: null,
        budgetMax: null,
        nbRoom: null,
        nbBedRoom: null,
        surfaceMin: null,
        ref: null
    };
    $scope.transferData = function(url, method, data) {
        $http({
            method: method,
            data: $.param(data),
            url: url,
            headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(
            function(response) {
                console.log(response);
            }, function (error) {
                console.log(error);
            });
    };
    $scope.submitForm = function($event) {
        $event.preventDefault();
        var sendURL = $('#filter_property_main').attr('action');
        var property_search = {
            '_token': $('#property_search__token').val(),
            'typeTransac': $('#property_search_typeTransac').val(),
            'town' : $scope.data.ville,
            'type' : $scope.data.type,
            'budgetMin' : $scope.data.budgetMin,
            'budgetMax' : $scope.data.budgetMax,
            'nbRoom' : $scope.data.nbRoom,
            'nbBedRoom' : $scope.data.nbBedRoom,
            'surfaceMin' : $scope.data.surfaceMin,
            'ref' : $scope.data.ref
        };
        $scope.transferData(sendURL, "post", property_search);
    };
}]);